import bus from '@/utils/bus'

let busName = {
    homeChildView: "homeChildView",
    onLogged: "onLogged",
    onLogout: "logout",
    lobbyInfo: "lobbyInfo",
}

export {
    bus,
    busName
}