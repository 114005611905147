<template>
  <div class="download">
    <div class="tab-content">
      <img loading="lazy" src="@/assets/pc/down/download-page.jpg" class="app-landing-banner" alt="">
      <div class="app-landing-content">
        <div class="header-container">
          <div class="header-1-title">
            <p>{{t('游戏的未来就在这里')}}</p>
            <p>{{t('任何设备都能运行的好游戏')}}</p>
            <p>{{t('全新应用')}}</p>
          </div>
          <div class="header-2">{{t('现在注册以获得无限奖励、优惠和奖品.')}}</div>
        </div>
        <div class="app-download-container">
          <div class="download-item">
            <vue-qr :logo-src="comApi.getLogoImg()" class="qr-img" :text="lobbyInfo.VersionGet.android.UrlPath" :size="100" :margin='10'>
            </vue-qr>
            <div class="os-name" @click="toDownClick()">{{t('安卓')}}</div>
            <div class="instruction-btn">{{t('操作说明')}}</div>
          </div>
        </div>
        <p class="app-ad-title">{{t('全新应用')}}</p>
        <div class="app-ad-container">
          <div class="ad-box box1-0">
            <p class="ad-title">{{t('轻松推荐')}}</p>
            <p class="ad-desc">{{t('分享推荐链接，赚取佣金')}}</p>
          </div>
          <div class="ad-box box1-1">
            <p class=" ad-title">{{t('无缝钱包转账')}}</p>
            <p class="ad-desc">{{t('几秒钟内完成转账')}}</p>
          </div>
          <div class="ad-box box2-0">
            <p class="ad-title">{{t('更高安全系数')}}</p>
            <p class="ad-desc">{{t('通过面部识别或指纹登入账户')}}</p>
          </div>
          <div class="ad-box box2-1">
            <p class="ad-title">{{t('快速登录')}}</p>
            <p class="ad-desc">{{t('在安全、快捷的方式下保持登录状态')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
function back() {
  router.go(-1);
}

function toDownClick() {
  if (tools.platform() == 'h5_android') {
    $act.openPage(lobbyInfo.value.VersionGet.android.UrlPath);
    return;
  }
  if (tools.platform() == 'h5_ios') {
    bus.emit(bus.event.iosDownPop, true);
    return;
  }
}

</script>

<style lang="scss" scoped>
.download {
  position: relative;
  background: #f3f5f6;
  color: #000;
  margin-top: 0 !important;
  .tab-content {
    margin: 0 10px;
    .app-landing-banner {
      width: 100%;
    }
    .app-landing-content {
      flex-direction: column;
      padding: 0;
      text-align: center;
      align-items: center;
      margin: -25px 0 0;
      display: flex;
      word-break: break-word;
      .header-container {
        width: 100%;
        .header-1-title {
          font-weight: 600;
          font-size: 23px;
          color: #0f161f;
        }
        .header-2 {
          margin: 25px 20px 0;
          font-size: 14px;
          color: #8697a2;
        }
      }
      .app-download-container {
        margin: 33px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .download-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .os-name {
            margin-top: 20px;
            width: 143px;
            border-radius: 5px;
            padding: 4px;
            font-size: 14px;
            background: #fff;
            color: #0f161f;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
              height: 20px;
              margin: 0 10px 0 0;
            }
          }
          .instruction-btn {
            margin: 10px 0 0;
            font-size: 15px;
          }
        }
      }
    }
    .app-ad-title {
      margin: 70px 22px 0;
      font-size: 16px;
      color: #0f161f;
      font-weight: 600;
    }
    .app-ad-container {
      margin: 25px 23px 0;
      display: grid;
      grid-template-areas: "box1 box2" "box3 box4";
      grid-gap: 10px;
      color: #fff;
      .ad-box {
        height: 230px;
        border-radius: 7px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 150px 2px 0;
        .ad-title {
          font-size: 14px;
        }
        .ad-desc {
          margin: 7px 0 0;
          font-size: 12px;
        }
      }
      .box1-0 {
        grid-area: box1;
        background-image: url("@/assets/pc/down/referral-made-easy.jpg");
      }
      .box1-1 {
        grid-area: box3;
        background-image: url("@/assets/pc/down/seamless-wallet-transfer.jpg");
      }
      .box2-0 {
        padding: 125px 20px 0 !important;
        grid-area: box2;
        background-image: url("@/assets/pc/down/higher-security.jpg");
      }
      .box2-1 {
        grid-area: box4;
        background-image: url("@/assets/pc/down/express-login.jpg");
      }
    }
  }
}
</style>
