<template>
  <div v-if="!obj.loading" class="depositInfo">
    <div class="form-group">
      <div class="pay-group">
        <label v-for="(item, index) in obj.data" :class="obj.activeName == index ? 'active' : ''" @click="bankItemClick(ite, index)">
          <div class="pay-btn">
            <img :src="comApi.getNormalImg(item.Icon)">
            {{ index }}
          </div>
        </label>
      </div>
    </div>
    <div v-if="obj.data[obj.activeName].PayJson.length > 0" class="pay-group  form-group methodBlock">
      <label v-for="(item, index) in obj.data[obj.activeName].PayJson" :class="index == obj.activeChildIndex ? 'active' : ''" @click="chitldItemClick(item, index)">
        <div class="pay-btn">
          <img :src="comApi.getNormalImg(item.icon)">
          {{ tools.getCurLangValue(item.methodShow) }}
        </div>
      </label>
    </div>
    <div v-if="obj.data[obj.activeName].PayJson.length > 0" class="FinalForm">
      <template v-if="obj.data[obj.activeName].Merchant == 'VgPay'">
        <DepositInfoVgpay :data="obj.data[obj.activeName]" :curPayData="obj.data[obj.activeName].PayJson[obj.activeChildIndex]" :payAmountList="obj.payAmountList" />
      </template>
      <template v-else-if="obj.data[obj.activeName].Merchant == 'Official'">
        <DepositInfoOfficial :data="obj.data[obj.activeName]" :curPayData="obj.data[obj.activeName].PayJson[obj.activeChildIndex]" :payAmountList="obj.payAmountList" />
      </template>
      <template v-else-if="obj.data[obj.activeName].Merchant == 'AutoCheck'">
        <DepositInfoAutoCheck :data="obj.data[obj.activeName]" :curPayData="obj.data[obj.activeName].PayJson[obj.activeChildIndex]" :payAmountList="obj.payAmountList" />
      </template>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import md5 from 'js-md5'
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import DepositInfoOfficial from "@/views/pc/components/member/DepositInfoOfficial.vue"
import DepositInfoVgpay from "@/views/pc/components/member/DepositInfoVgpay.vue"
import DepositInfoAutoCheck from "@/views/pc/components/member/DepositInfoAutoCheck.vue"
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const obj = reactive({
  loading: true,
  activeName: '',
  activeChildIndex: 0,
})

if (isLogged.value) {
  getPayInfo();
}
bus.on('onConnected', () => {
  getPayInfo();
})
function getPayInfo() {
  mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
    log.info('payInfo---', data.Data)
    if (data.Code == 0) {
      obj.data = tools.sortObject(data.Data.PayConfList, ['Priority']);
      obj.payAmountList = splitStr(data.Data.PayAmountList);
      for (let key in obj.data) {
        obj.activeName = key;
        break;
      }
      obj.loading = false;
    } else {

    }
  });
}

function splitStr(str) {
  let arr = str.split(","); // 使用 , 作为分隔符对字符串进行切割
  let newArr = arr.filter(function (s) {
    return s && s.trim();
  });
  return newArr;
}

function bankItemClick(item, index) {
  obj.activeName = index;
  obj.activeChildIndex = 0;
}

function chitldItemClick(item, index) {
  obj.activeChildIndex = index;
}

</script>

<style lang="scss" scoped>
.depositInfo {
  .form-group {
    position: relative;
    margin-bottom: 30px;
    *zoom: 1;

    h4 {
      margin: 0;
      color: #0f161f;
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;

      span {
        position: relative;
      }

      span:after {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #c00;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        z-index: 1;
        content: "";
        display: inline-block;
        right: -15px;
      }
    }

    .receiptImgFrame {
      position: relative;
      margin-top: 10px;

      input[type="file"] {
        display: block;
      }

      .imgUpload {
        width: 100%;
        height: 100%;
        opacity: 0;
        background: none;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
      }

      .imgUpload:hover + label {
        background: #f7f7f7;
        border: #f7f7f7;
      }

      label {
        position: relative;
        cursor: pointer;
        display: inline-block;
      }

      .imgUpload + label {
        border-radius: 5px;
        background: #f6f6f6;
        border: 1px solid #dbe0e4;
        margin: 0;
        padding: 50px 0;
        width: 100%;
        text-align: center;

        .upload-image {
          max-height: 200px;
          max-width: 100%;
        }

        span {
          display: block;
          margin-top: 20px;
          color: #333;
        }
      }
    }
  }

  .pay-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 -5px;

    label {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 10px;
      padding: 0 5px;
      width: 25%;
      vertical-align: top;
      cursor: pointer;

      .pay-btn {
        border-radius: 5px;
        background: #fff;
        border: 1px solid #cbd4e6;
        padding: 20px 10px;
        width: 100%;
        color: #999;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 20px 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        word-break: break-word;
      }
    }

    .active,
    label:hover {
      .pay-btn {
        background-image: linear-gradient(
            -1deg,
            #ffdeda 8%,
            #ffe9e6 50%,
            #fef2ef 100%
          ),
          linear-gradient(#ffdeda, #ffdeda);
        border: 1px solid #ff9384;
        color: #000;
      }
    }

    img {
      width: 30px;
      height: 30px;
      display: inline-block;
      margin: 0 5px 0 0;
      max-width: none;
    }
  }

  .methodBlock {
    position: relative;
    border-radius: 5px;
    background: #ffffff;
    padding: 10px 10px 20px;
    margin: 0 !important;

    .active,
    label:hover {
      .pay-btn {
        background: #fffef3;
        border-color: #ffc22f;
      }
    }
  }

  .methodBlock:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-color: transparent transparent #f9f9f9;
    border-style: solid;
    border-width: 0 10px 20px;
  }

  .FinalForm {
    :deep(.el-date-editor) {
      width: 100%;
      height: 50px;

      .el-input__wrapper {
        font-size: 16px;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 1px solid #dbe0e4;

        .el-input__icon {
          font-size: 20px;
        }
      }

      .is-focus {
        color: #000;
        border-color: #f7f7f7;
        background-color: #f7f7f7;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    :deep(.el-select) {
      width: 100%;

      .el-input__wrapper {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid #dbe0e4;

        .el-input__inner {
          height: 50px !important;
          display: block;
          width: 100%;
          padding: 10px;
          font-size: 16px;
          line-height: 30px;
          color: #616161;
          background: #fff;

          -webkit-box-shadow: none;
          box-shadow: none;
          -webkit-transition: none;
          transition: none;
          border-radius: 0;
        }
      }

      .el-input--suffix .el-input__inner {
        padding-right: 30px;
      }
    }

    .form-input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 10px;
      font-size: 16px;
      line-height: 30px;
      color: #616161;
      background: #fff;
      border: none;
      border-bottom: 1px solid #dbe0e4;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-transition: none;
      transition: none;
      border-radius: 0;
    }

    .form-input:focus {
      color: #000;
      border-color: #f7f7f7;
      background-color: #f7f7f7;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .form-input[disabled] {
      color: #aaa;
      background: #f3f3f3;
      border-radius: 5px;
      border: 0 solid transparent;
      opacity: 1;
      -webkit-text-fill-color: #aaa;
      cursor: not-allowed;
    }

    .poperrorMsg {
      position: absolute;
      z-index: 20;
      bottom: 50px;
      right: 0;
      width: auto;
      background: #c00;
      color: #fff;
      line-height: 20px;
      padding: 5px 10px;
      border-radius: 3px;
      -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    }

    .poperrorMsg:before {
      content: "";
      font-family: glyphicon;
      font-weight: 600;
      margin-right: 5px;
      top: 1px;
      position: relative;
    }

    .poperrorMsg:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      margin: auto;
      right: 10px;
      bottom: -6px;
      display: block;
      border-color: #c00 transparent transparent;
      border-style: solid;
      border-width: 7px 7px 0;
    }

    .money-btn {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .submitbtn-wrap {
      text-align: center;

      button {
        background-image: linear-gradient(0deg, #ff8a00 4%, #ff8a00 100%),
          linear-gradient(#9b9b9b, #9b9b9b);
        background-repeat: repeat-x;
        transition: all 0.5s ease;
        cursor: pointer;
        display: inline-block;
        width: auto;
        height: auto;
        padding: 10px 40px;
        font-size: 18px;
        color: #fff;
        line-height: 30px;
        border: none;
        border-radius: 50px;
        margin: 0 auto;
      }

      button:hover {
        background-repeat: repeat-x;
      }
    }

    .notice-box {
      width: 100%;
      margin-top: 20px;
      display: flex;

      .notice-left {
        width: 25%;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        background-image: linear-gradient(
            0deg,
            #ff8a00 2%,
            #ff8a00 43%,
            #ff8a00 100%
          ),
          linear-gradient(#ff8a00, #ff8a00);
        border: 1px solid #ff8a00;
        padding: 20px 10px;
        color: #474;
        text-align: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .pay-img {
          position: relative;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #ceddce;
          margin: 0 auto 10px;
          line-height: 100px;
          overflow: hidden;

          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            vertical-align: middle;
          }
        }
      }

      .notice-right {
        width: 75%;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        background: #fff;
        border: 1px solid #cecbf7;
        border-left: 0;
        padding: 10px 20px 20px;

        .totalProfit {
          .row-layout {
            border-bottom: 1px dotted #0071e3;
            padding: 15px 10px;
            position: relative;
            display: flex;
            align-items: center;

            .row-left {
              width: 75%;
              color: #666;
            }

            .row-right {
              width: 25%;
              border-left: 1px dotted #ceddce;
              color: #000;
              text-align: right;
            }
          }
        }

        .tips-box {
          border-radius: 5px;
          background: #f6f8fb;
          border: 1px solid #efebdc;
          padding: 20px 20px 20px 50px;
          position: relative;
          color: #642;
          margin-bottom: 0;
          margin-top: 20px;
        }

        .tips-box::before {
          content: "";
          left: 20px;
          top: 20px;
          position: absolute;
          z-index: 10;
          font-family: glyphicon;
          font-size: 18px;
          font-weight: 600;
        }

        .overflowContent {
          overflow-x: auto;
        }
      }
    }
  }
}
</style>