<template>
  <div class="depositInfoAutoCheck">
    <div class="form-group">
      <div class="notice-box">
        <!-- <div class="notice-left">
          <vue-qr class="qr-img" :text="props.curPayData.link" :size="120">
          </vue-qr>
        </div> -->
        <div class="notice-right">
          <div class="totalProfit">
            <!-- <div class="row-layout">
              <div class="row-left">{{t('Bank Name')}}</div>
              <div class="row-right">{{
                                        props.curPayData.bankName }}
              </div>
            </div> -->
            <div class="row-layout">
              <div class="row-left">{{t('Bank Account Holder')}}</div>
              <div class="row-right"> {{
                                        props.curPayData.bankAccout
                                    }}
              </div>
            </div>
            <div class="row-layout">
              <div class="row-left">{{t('Bank Account')}}</div>
              <div class="row-right"> {{
                                        props.curPayData.bankCard
                                    }}
                <i class="fa fa-copy" @click="copyUrl(props.curPayData.bankCard)"></i>
                <span class="copy" @click="copyUrl(props.curPayData.bankCard)">{{t('Copy')}}</span>
              </div>
            </div>
          </div>
          <!-- <div class="tips-box">
            <div class="v-html-default overflowContent blockContent" v-html="tools.getCurLangValue(props.curPayData.content)"></div>
          </div> -->
        </div>
      </div>
      <!-- <div class="form-group">
        <h4><span>{{t('Full Name')}}</span></h4>
        <div>
          <input type="text" v-model="obj.curBankItem.AccountName" :disabled="true" class="form-input" @input="validateCardName()">
          <div v-show="cardNameShowErr" class="poperrorMsg">
            {{t('Please enter a bank account name')}}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import native from "@/components/core/native";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage, ElNotification } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import md5 from 'js-md5'
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
import { Toast } from "vant";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const [amountShowErr, cardNameShowErr, bankNameShowErr] = [ref(false), ref(false), ref(false)];
const [uploadImgShowEff] = [ref(false)];
const props = defineProps(reactive({
  data: {},
  curPayData: {},
  payAmountList: [],
}))
const obj = reactive({
  loading: true,
  payAmountIndex: -1,
  MyDouDouBT: [],
  bankShowName: '',
  curBankItem: {
    AccountName: ''
  },
  money: '',
  uploadImg: '',
  uploadState: false,
  markFlag: '',
})
//上传图片
let uploadObj = reactive({
  url: lobbyInfo.value.TcpInfo.upload,
  scene: 'depositPhoto'
})

let timeFuc = null;
if (isLogged.value) {
  getUserInfo();
}
bus.on('onConnected', () => {
  getUserInfo()
})
bus.on(bus.event.getBankSelectInfo, (data) => {
  obj.curBankItem = data;
  let str = data.BtName + ' ' + data.CardNum;
  obj.bankShowName = str;
  bankNameShowErr.value = false;
})


function getUserInfo() {
  let parms = {
  }
  mqant.request(topic.getUserInfoCq, parms, function (data, topicName, msg) {
    log.info('getUserInfoCq--', data.Data)
    if (data.Code == 0) {
      obj.MyDouDouBT = data.Data.MyDouDouBT;
      if (obj.MyDouDouBT.length == 1) {
        let str = obj.MyDouDouBT[0].BtName + ' ' + obj.MyDouDouBT[0].CardNum;
        obj.bankShowName = str;
        obj.curBankItem = obj.MyDouDouBT[0]
      }
      obj.loading = false;
      //首次随机打乱
      updateBankFirst();
      //银行卡充值每分钟更新排序
      updateBankSort();
    } else {
    }
  });
}

function checkNeedUpdateBank() {
  if (
    obj.bankShowName == '' &&
    obj.curBankItem.AccountName == '' &&
    obj.money == '' &&
    obj.uploadImg == '' &&
    obj.markFlag == '') {
    return true;
  }
  return false;
}

function stopUpdateSort() {
  timeFuc && clearTimeout(timeFuc);
}

function updateBankFirst() {
  let randomListBySelf = function (list) {
    return list.sort(() => Math.random() - 0.5);
  }
  props.data.PayJson = randomListBySelf(props.data.PayJson);
}
function updateBankSort() {
  stopUpdateSort();
  let randerArr = function (arr) {
    var newArr = arr.slice(0);
    var len = arr.length;
    var indexArr = [];
    for (var i = 0; i < len; i++) {
      if (indexArr[i]) {
        continue;
      }
      var random = Math.floor(Math.random() * len);
      while (random === i) {
        random = Math.floor(Math.random() * len);
      }
      indexArr[random] = indexArr[i] = true;
      var swap = newArr[i];
      newArr[i] = newArr[random];
      newArr[random] = swap;
    }
    return newArr
  }
  timeFuc = setInterval(() => {
    if (!checkNeedUpdateBank()) {
      stopUpdateSort();
      return;
    }
    props.data.PayJson = randerArr(props.data.PayJson);
  }, 60000);
}

onBeforeUnmount(() => {
  timeFuc && clearTimeout(timeFuc);
});


function payItemClick(item, index) {
  obj.payAmountIndex = index;
  obj.money = Number(item);
  amountShowErr.value = false;
}

bus.off(bus.event.openCameraCallBack);
bus.on(bus.event.openCameraCallBack, (data) => {
  console.log('openCameraCallBack data-------------', data);
  // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwA…lYEf2uPdqfCzXFFNz94v5A/IOJyufQ6yQAAAAAElFTkSuQmCC
  let urlBase64 = "data:image/png;base64," + data.result;
  // 将 Base64 编码的图片数据转换为 Blob 对象
  const blob = base64ToBlob(urlBase64);
  // 创建 File 对象
  const file = new File([blob], "uploaded_image.jpg" + Date.now(), { type: "image/jpeg" });
  upladFile(file)
})

function base64ToBlob(base64) {
  // 移除 Base64 编码的前缀
  const base64WithoutPrefix = base64.split(",")[1];
  const byteString = atob(base64WithoutPrefix);
  // 转换为 Uint8Array
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  // 创建 Blob 对象
  const blob = new Blob([uint8Array], { type: "image/png" });
  return blob;
}

function uploadImgNative() {
  native.openCamera();
  log.info('调用了前端openCamera')
}

function uploadImg(event) {
  let file = event.target.files[0];
  upladFile(file)
}

function upladFile(file) {
  let form = new FormData(); // FormData 对象
  form.append("file", file); // 文件对象
  form.append("scene", uploadObj.scene); // 文件对象
  form.append("filename", md5(file.name)); // 文件对象
  let xhr = new XMLHttpRequest();  // XMLHttpRequest 对象
  xhr.open("post", uploadObj.url + '/group1/upload', false); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
  xhr.onload = uploadComplete; //请求完成
  xhr.onerror = uploadFailed; //请求失败
  // xhr.upload.onprogress = progressFunction;//【上传进度调用方法实现】
  // xhr.upload.onloadstart = function () {//上传开始执行方法
  //     ot = new Date().getTime();   //设置上传开始时间
  //     oloaded = 0;//设置上传开始时，以上传的文件大小为0
  // };
  xhr.send(form); //开始上传，发送form数据
}

function uploadComplete(evt) {
  let url = evt.target.responseText;
  obj.uploadState = true;
  obj.uploadImg = url;
  uploadImgShowEff.value = false;
}

function uploadFailed(evt) {
  log.error('uploadFailed')
}

function validateMoney() {
  if (!obj.money || isNaN(Number(obj.money, 10))) {
    amountShowErr.value = true;
  } else {
    amountShowErr.value = false;
  }
}

function toSelectBank() {
  bus.emit(bus.event.bankSelectPop, { bool: true, data: { list: obj.MyDouDouBT } })
}

function getPayAmountList() {
  let arr = []
  let curMin = props.curPayData.Min;
  let curMax = props.curPayData.Max;
  for (let i = 0; i < props.payAmountList.length; i++) {
    let info = Number(props.payAmountList[i]) * 1000;
    if (info >= curMin && info <= curMax) {
      arr.push(props.payAmountList[i]);
    }
  }
  if (tools.isMobile()) {
    arr.splice(6);
  }
  return arr;
}

function submit() {
  validateMoney();
  bankNameShowErr.value = !obj.bankShowName ? true : false;
  uploadImgShowEff.value = !obj.uploadState ? true : false;
  if (amountShowErr.value || bankNameShowErr.value || uploadImgShowEff.value) {
    return;
  }
  if (obj.MyDouDouBT.length <= 0) {
    ElNotification({
      title: t('Deposit Warning'),
      message: t('Please select a linked bank card'),
      type: 'warning',
    })
    // router.push('/member/profile/personal')
    return;
  }
  let objSubmit = {
    merchant: props.data.Merchant,
    methodType: props.data.MethodType,
    payOid: props.data.PayOid,
    methodId: props.curPayData.MethodId,
    amount: Number(obj.money) * 1000,
    btOid: '',
    accountName: obj.curBankItem.AccountName,
    btOid: obj.curBankItem.Oid,
    chargePic: obj.uploadImg,
    chargeRemark: obj.markFlag
  }

  log.info('objSubmit', objSubmit)
  mqant.request(topic.chargeNew, objSubmit, function (data, topicName, msg) {
    log.info('topic.chargeNew', data)
    if (data.Code == 0) {
      obj.money = '';
      obj.payAmountIndex = -1;
      obj.uploadImg = '';
      obj.markFlag = '';
      obj.uploadState = false;
      ElMessage.success(data.ErrMsg);
    } else {
      ElMessage.error(data.ErrMsg);
    }
  })
}


function copyUrl(text) {
  tools.copyText(text).then(
    (succ) => {
      ElMessage.success(t("Copy successful"));
    },
    (err) => {
    }
  );
}

</script>


<style lang="scss" scoped>
.depositInfoAutoCheck {
  .form-group {
    position: relative;
    margin-bottom: 10px;
    *zoom: 1;

    h4 {
      margin: 0;
      color: #9e5045;
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;

      span {
        position: relative;
      }

      span:after {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #c00;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        z-index: 1;
        content: "";
        display: inline-block;
        right: -15px;
      }
    }

    .receiptImgFrame {
      position: relative;
      margin-top: 10px;

      input[type="file"] {
        display: block;
      }

      .imgUpload {
        width: 100%;
        height: 100%;
        opacity: 0;
        background: none;
        position: absolute;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
      }

      .imgUpload:hover + label {
        background: #fef0f2;
        border: #fef0f2;
      }

      label {
        position: relative;
        cursor: pointer;
        display: inline-block;
      }

      .imgUpload + label {
        border-radius: 5px;
        background: #f6f6f6;
        border: 1px solid #ddd;
        margin: 0;
        padding: 10px 0;
        width: 100%;
        text-align: center;

        .upload-image {
          max-height: 200px;
          max-width: 100%;
          width: 80px;
        }

        span {
          display: block;
          margin-top: 10px;
          color: #333;
        }
      }
    }
  }

  .pay-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 -5px;

    label {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-top: 10px;
      padding: 0 5px;
      width: 50%;
      font-size: 14px;
      vertical-align: top;
      cursor: pointer;

      .pay-btn {
        border-radius: 5px;
        background: #fff;
        border: 1px solid #cbd4e6;
        padding: 5px;
        width: 100%;
        color: #999;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        word-break: break-word;
      }
    }

    .active {
      .pay-btn {
        background-image: linear-gradient(
            -1deg,
            #ffdeda 8%,
            #ffe9e6 50%,
            #fef2ef 100%
          ),
          linear-gradient(#ffdeda, #ffdeda);
        border: 1px solid #ff9384;
        color: #000;
      }
    }

    img {
      width: 30px;
      height: 30px;
      display: inline-block;
      margin: 0 5px 0 0;
      max-width: none;
    }
  }

  .methodBlock {
    position: relative;
    border-radius: 5px;
    background: #ececfa;
    padding: 0 5px 10px;
    margin: 0 !important;

    .active {
      .pay-btn {
        background: #fffdeb;
        border-color: #f7d5b3;
      }
    }
  }

  .methodBlock:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-color: transparent transparent #f9f9f9;
    border-style: solid;
    border-width: 0 10px 20px;
  }

  :deep(.el-date-editor) {
    width: 100%;
    height: 50px;

    .el-input__wrapper {
      font-size: 16px;
      box-shadow: none;
      border-radius: 0;
      border-bottom: 1px solid #ddd;

      .el-input__icon {
        font-size: 20px;
      }
    }

    .is-focus {
      color: #000;
      border-color: #fef0f2;
      background-color: #fef0f2;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  :deep(.el-select) {
    width: 100%;

    .el-input__wrapper {
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #ffafa4;

      .el-input__inner {
        height: 50px !important;
        display: block;
        width: 100%;
        padding: 10px;
        font-size: 16px;
        line-height: 30px;
        color: #616161;
        background: #fff;

        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        transition: none;
        border-radius: 0;
      }
    }

    .el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
  }

  .form-input {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 16px;
    line-height: 30px;
    color: #616161;
    background: #fff;
    border: none;
    border-bottom: 1px solid #ffafa4;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    transition: none;
    border-radius: 0;
  }

  .form-input:focus {
    color: #000;
    border-color: #fef0f2;
    background-color: #fef0f2;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .form-input[disabled] {
    color: #aaa;
    background: #f3f3f3;
    border-radius: 5px;
    border: 0 solid transparent;
    opacity: 1;
    -webkit-text-fill-color: #aaa;
    cursor: not-allowed;
  }

  .poperrorMsg {
    position: absolute;
    z-index: 20;
    bottom: 50px;
    right: 0;
    width: auto;
    background: #c00;
    color: #fff;
    line-height: 20px;
    padding: 5px 10px;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  }

  .poperrorMsg:before {
    content: "";
    font-family: glyphicon;
    font-weight: 600;
    margin-right: 5px;
    top: 1px;
    position: relative;
  }

  .poperrorMsg:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    margin: auto;
    right: 10px;
    bottom: -6px;
    display: block;
    border-color: #c00 transparent transparent;
    border-style: solid;
    border-width: 7px 7px 0;
  }

  .money-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .submitbtn-wrap {
    text-align: center;

    button {
      background-image: linear-gradient(0deg, #fe725d 4%, #f8a398 50%),
        linear-gradient(#9b9b9b, #9b9b9b);
      background-repeat: repeat-x;
      transition: all 0.5s ease;
      cursor: pointer;
      display: inline-block;
      width: auto;
      height: auto;
      padding: 10px 40px;
      font-size: 18px;
      color: #fff;
      line-height: 30px;
      border: none;
      border-radius: 50px;
      margin: 0 auto;
    }

    button:hover {
      // background-image: linear-gradient(180deg, #1a9090 0, #2bb);
      background-repeat: repeat-x;
    }
  }

  .notice-box {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .notice-left {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-image: linear-gradient(
          0deg,
          #ffe4e1 2%,
          #ffe4e1 43%,
          #ffe4e1 100%
        ),
        linear-gradient(#ffe4e1, #ffe4e1);
      border: 1px solid #ffe4e1;
      padding: 10px;
      color: #474;
      text-align: center;
      font-size: 16px;
      line-height: 60px;

      .pay-img {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #ceddce;
        margin: 0 auto 10px;
        line-height: 100px;
        overflow: hidden;

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 100%;
          vertical-align: middle;
        }
      }
    }

    .notice-right {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid #cecbf7;
      background: #fff;
      border: 1px solid #ffafa4;
      padding: 10px;

      .totalProfit {
        .row-layout {
          border-bottom: 1px dotted #ffafa4;
          padding: 10px;
          position: relative;
          font-size: 16px;
          // display: flex;
          // align-items: center;

          .row-left {
            width: 100%;
            color: #666;
          }
          .row-right {
            width: 100%;
            color: #000;
            text-align: left;
            .copy {
              color: #118fc5;
              margin-left: 2px;
              cursor: pointer;
            }
            i {
              cursor: pointer;
              margin-left: 5px;
              width: 16px;
              height: 16px;
              color: #118fc5;
            }
          }
        }
      }

      .tips-box {
        border-radius: 5px;
        background: #ffebdf;
        border: 1px solid #efebdc;
        padding: 10px 20px 10px 50px;
        position: relative;
        color: #642;
        margin-bottom: 0;
        margin-top: 10px;
      }

      .tips-box::before {
        content: "";
        left: 20px;
        top: 20px;
        position: absolute;
        z-index: 10;
        font-family: glyphicon;
        font-size: 18px;
        font-weight: 600;
      }

      .overflowContent {
        overflow-x: auto;
      }
    }
  }
}
</style>